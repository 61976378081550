const zh = {
    process: {
        defaultFormName: '未命名表单',
        rootName: '发起人',
        rootDesc: '任何人',
        taskName: '任务',
        remark: '备注说明',
        next: '下一步',
    },
    common: {
        download: '下载',
        yes: '确定',
        ok: "确定",
        cancel: '取消',
        exit: '退出',
        image: '图片',
        title: '工作流管理',
        popTips: '提示',
        select: '选择',
        submit: '提交',
        modify: '修改',
        delete: '删除',
        yyyy: 'yyyy',
        yyyyMM: 'yyyy-MM',
        yyyyMMdd: 'yyyy-MM-dd HH:mm',
        yyyyMMddHHmm: 'yyyy-MM-dd HH:mm',
    },
    tips: {
        addSuccess: "添加成功",
        editSuccess: "修改成功",
        deleteSuccess: "删除成功",
        moveSuccess: "移动成功"
    },
    login: {
        username: '账号',
        password: '密码',
        login: '登 录',
        ruleUsername: '请输入您的账号',
        rulePassword: '请输入您的密码',
    },
    formPanel: {
        createProcess: '创建工作流',
        createGroup: '新建分组',
        emptyTips: "暂无流程审批",
        exitPopMsg: '您确定要退出吗?',
        edit: "编辑分组",
        createTemplate: '创建新表单',
        updateGroup: '修改名称',
        deleteGroup: '删除分组',
        popGroupMsg: '请输入分组名称',
        popDeleteGroupMsg: '确定要删除分组{name}?',
        popDeleteMsg: '您确定要删除表单{name}吗，删除后无法恢复',
        popStopMsg: '您是否要{edit}{name}表单吗？',
        selectGroup: "请选择分组",
        errorMsg: '分组名不能为空且长度小于30',
        button: {
            edit: '修改',
            use: '启用',
            stop: '停用',
            delete: '删除',
            move: '移动',
        }
    },
    FormProcessDesign: {
        title: {
            baseSetting: "基础信息",
            formSetting: "工作流表单",
            processDesign: "执行流程",
            proSetting: "扩展设置",
        },
        validResultErr: '项错误',
        exitMsg: "未发布的内容将不会被保存，是否直接退出 ?",
        publish: '发布',
        publishMsg: '如果您只想预览请选择预览，确认发布后流程立即生效，是否继续?',
        popTips: '设置项检查',
        defaultValidErr: {
            a: '请检查基础设置项',
            b: '请检查审批表单相关设置',
            c: '请检查审批流程，查看对应标注节点错误信息',
            d: '请检查扩展设置',
            default: '未知错误',
        },
        validResult: {
            title: '检查中...',
            action: '处理',
            desc: '正在检查设置项',
            checkSuccess: '校验完成',
            checkFail: '校验失败',
            checkDesc: '设置项校验成功，是否提交？',
            submit: '提 交',
            modify: '去修改'
        }
    },
    baseSetting: {
        iconTitle: '表单图标',
        background: '选择背景色',
        icon: '选择图标',
        formName: '名称',
        remark: '说明',
        group: '分组',
        errorTips: {
            name: '表单名称未设置',
            group: '表单分组未设置'
        },
        ruleFormName01: '请输入表单名称',
        ruleFormName02: '表单名称长度在1-20之间',
        ruleFormRemark: '请输入表单描述',
        ruleFormGroup: '请选择所在分组'
    },
    formDesign: {
        title: '组件库',
        preview: '预览表单',
        mobile: '移动端',
        PC: 'PC端',
        centerTips: "请在左侧选择控件并拖至此处",
        rightTips: '选中控件后在这里进行编辑',
        delMsg: '删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?',
        errorTips: {
            form: '表单为空，请添加组件',
            nameRepeat: '表单 {title} 名称重复',
            noSetOptions: '{title}未设置选项',
            noAdd: "分栏内未添加组件"
        },
    },
    proSetting: {
        signMessage: '审批同意时是否签字',
        inactive: '无需签字',
        active: '需要签字',
        signTip: '如果此处设置为需要签字，则所有执行人“同意时” 必须签字'
    },
    formConfig: {
        formName: '表单名称',
        required: '必填项',
        emptyTips: '当前组件不支持配置',
        placeholderLabel: '提示文字',
        placeholderValue: '请设置提示语',
        decimal: '保留小数',
        showChinese: '展示大写',
        color: '文字颜色',
        multipleLabel: '是否多选',
        select: {
            label: '选项设置',
            addLabel: "新增选项",
            addValue: "新选项",
            placeholder: '请设置选项值'
        },
        date: {
            dateTimeTips: '请设置日期提示',
            dateTimeFormatLabel: '日期格式',
            yyyy: '年',
            yyyyMM: '年-月',
            yyyyMMdd: '年-月-日',
            yyyyMMddHHmm: '年-月-日 时:分',
            showLengthLabel: '展示时长',
            startTimePlaceholder: '开始日期',
            endTimePlaceholder: '结束日期',
        },
        upload: {
            numLimitLabel: '数量限制',
            numLimitTips: '限制最大上传图片数量(为0则不限制)',
            numLimitPlaceholder: '最多上传几张图片',
            sizeLimitLabel: '大小限制',
            sizeLimitTips: '限制单个文件最大大小-MB(为0则不限制)',
            sizeLimitPlaceholder: '单个文件最大大小',
            typeLimitLabel: '类型限制',
            typeLimitTips: '限制上传文件的后缀类型',
            typeLimitPlaceholder: '允许上传文件的后缀格式，可设置多种',
            onlyReadLabel: "不可下载",
            imageSizeLimitTips: '限制单个图片最大大小-MB（为0则不限制）',
            imageSizeLimitPlaceholder: '单个文件最大大小',
            imageNumLimitTips: '限制最大上传图片数量（为0则不限制）',
            imageNumLimitPlaceholder: '最多上传几张图片'
        }
    },
    formItem: {
        inputPlaceholder: '请输入内容',
        numberPlaceholder: '请输入数值',
        amountPlaceholder: '请输入金额',
        amountChinese: '大写',
        descPlaceholder: '只是一段说明文字',
        selectPlaceholder: '请选择选项',
        datePlaceholder: '请选择日期时间',
        prickerPlaceholder: '选择人员',
        dateRange: {
            timeLengthDefault: '0 （时长为0，请确认）',
            timeLength: '时长',
            tips: "先选择时间",
            year: '年 ',
            month: '个月 ',
            day: '天 ',
            hour: '小时 ',
            minutes: '分钟 '
        },
        upload: {
            selectFile: '选择文件',
            selectFilePlaceholder: '请选择附件',
            sizeTip1: ' | 只允许上传[只允许[{data}]格式的文件，且单个附件不超过{maxSize}MB',
            sizeTip2: ' | 单个附件不超过{maxSize}MB',
            beyondUploadTip: '单张图片最大不超过{maxSize}MB',
            selectImagePlaceholder: '请选择图片',
            imageSizeTip: '| 每张图不超过${maxSize}MB',
            notSupportedFormat: '存在不支持的图片格式'
        },
        name: {
            text: '文本',
            textInput: '单行文本输入',
            textareaInput: '多行文本输入',
            numberInput: '数字输入框',
            amountInput: '金额输入框',
            description: '说明文字',
            select: '选项',
            selectInput: '单选框',
            multipleSelect: '多选框',
            options: ['选项1', '选项2'],
            date: '日期',
            dateTime: '日期时间点',
            dateTimeRange: '日期时间区间',
            upload: '上传',
            imageUpload: '上传图片',
            fileUpload: '上传附件',
            other: '其他',
            userPicker: '人员选择',
        }
    },
    node: {
        base: {
            title: '标题',
            placeholder: '请设置',
            errorInfo: '无信息'
        },
        tree: {
            maxLimit: "最多只能添加 8 项",
            errorTips: '完整流程必须有一个任务',
            errorTips2: '出现错误，找不到上级节点',
            add: '添加',
        },
        rootNode: {
            title: '发起人',
            placeholder: '所有人',
            defaultContent: '所有人',
        },
        approvalNode: {
            name: "任务",
            placeholder: '请设置执行人',
            assignUserDefault: '请指定执行人',
            selfDefault: '发起人自己',
            selfSelect: {
                one: '发起人自选一人',
                more: '发起人自选多人'
            },
            formUser: {
                noSelect: '表单内联系人（未选择）',
                select: '表单（{title}）内的人员',
                remove: '该表单已被移除'
            },
            default: '未知设置项',
            validate: {
                validateName: "任务名称未设置",
                validateToUser: '执行人为空时， 转交给指定人员：【请指定一个具体的人】',
                validateAssignUser: '{name}请指定执行人员',
                validateFormUser: '{name}请指定表单中的人员'
            }
        },
        ccNode: {
            name: "抄送人",
            placeholder: '请设置抄送人',
            contentDefault: '由发起人指定',
            validate: {
                errorInfo: '请选择需要抄送的人员',
                showError: '抄送节点 {name} 未设置抄送人'
            }
        },
        conditionNode: {
            name: "条件分支",
            condition: '条件',
            levelLabel: '优先级',
            copy: '复制条件',
            placeholder: '请设置条件',
            elsePlaceholder: '其他条件进入此流程',
            belongDefault: '{title}属于[]',
            belongSelect: '{title}属于{value}',
            belongSelect2: '{title}属于[{value}]之一',
            openRight: "其他条件内容无需编辑修改",
            moveLeft: "其他条件内容无需编辑修改",
            moveRight: '其他条件优先级必须最低',
            validate: {
                nullError: '条件分支后不能为空',
                placeholder: '请设置分支条件',
                noSet: '{name}未设置条件',
                noSetConditions1: '请设置条件组{name}内的条件',
                noSetConditions2: '条件 {name} 条件组{child}内未设置条件',
                perfectConditions1: '请完善条件组{name}内的${title}条件',
                perfectConditions2: '条件 {name} 条件组{groupName}内{title}条件未完善',
            }
        },
        end: "流程结束",
    },
    nodeConfig: {
        formPermLabel: '表单权限设置',
        nodeRootLabel: '设置发起人',
        nodeTaskLabel: '设置任务',
        nodeCCLabel: '设置抄送人',
        formAuthority: {
            title: '表单字段',
            readOnly: '只读',
            editable: '可编辑',
            hide: '隐藏',
        },
        root: {
            desc: '选择能发起该任务的人员，不选则默认开放给所有人',
            select: "请选择",
            tips: '请选择可发起本任务的人员'
        },
        CC: {
            select: '选择抄送人',
        },
        Task: {
            name: "任务名称",
            desc: "任务描述",
            descPlaceholder: '请输入任务描述',
            userType: '选择执行对象',
            assignUser: "选择人员",
            taskDelayLabel: '执行时间',
            taskDelay: '勾选后，发起任务时发起人可以选择该任务的执行时间',
            delayTimeLabel: "当天 ",
            taskDelayPlaceholder: '选择任务下发时间',
            broadcast: '播报内容，发起人填写，执行人收到任务时播报',
            listing: '任务清单，发起人填写，执行人收到后需逐项确认',
            userStatistics: '人员识别，拍视频时进行人员识别统计',
            selfSelect: {single: '自选一个人', multiple: '自选多个人'},
            formUser: '选择表单联系人项',
            formUserPlaceholder: '请选择包含联系人的表单项',
            self: '发起人自己作为执行人进行审批',
            mode: {label: '多人执行时审批方式', next: '会签 （按选择顺序执行，每个人必须执行）', and: '会签（可同时执行，每个人必须执行）'},
            AdvancedSettings: '其他设置',
            faceVerification: '执行任务时需要人脸识别验证身份',
            assigneeLabel: '该任务是否可以转交',
            signLabel: '执行同意时是否需要签字',
            signTips: '如果全局设置了需要签字，则此处不生效',
            commentLabel: '执行人完成时是否需要填写处理意见',
            uploadTypeLabel: '执行任务时必须上传以下项目',
            uploadType: {location: '位置', image: '图片', video: '视频', recording: '录音', file: '文件'},
            pickerTitle: {user: '请选择人员', role: '请选择系统角色'},
            approvalTypes: {assignUser: '指定人员', selfSelect: '发起人自选', self: '发起人自己', formUser: '表单内联系人'},
            selectOrg: {user: '用户', role: '角色'},
            yes: '是',
            no: '否'
        },
        condition: {
            levelLabel: '调整优先级',
            modifyLevelLabel: '拖拽条件调整优先级顺序',
            priority: '优先级',
            level: '第{nowNodeLeave}级',
            groupsLabel: '条件组关系',
            and: '且',
            or: '或',
            addGroup: '添加条件组',
            addGroupDesc: '只有必填选项才能作为审批条件',
            maxLimit: '最多只能添加10个条件组'
        },
        conditionGroupItem: {
            name: '条件组',
            groupTypeLabel: '组内条件关系',
            groupContentDefault: '点击右上角 + 为本条件组添加条件',
            addGroupTitle: '选择审批条件',
            decider: '判断符',
            equal: '等于',
            contained: '包含在',
            selectPlaceholder: '选择值',
            comparePlaceholder: "输入比较值",
            comparePlaceholder2: "输入可能包含的值",
            userDesc: '属于为某些人其中之一',
            selected: '请选择',
            explains: {
                "=": '等于',
                ">": '大于',
                '>=': '大于等于',
                '<': '小于',
                '<=': '小于等于',
                'IN': '包含在',
                'B': 'x < 值 < y',
                'AB': 'x ≤ 值 < y',
                'BA': 'x < 值 ≤ y',
                'ABA': 'x ≤ 值 ≤ y',
            }
        }
    },
    orgPicker: {
        searchPlaceholder: '搜索人员',
        selectAll: '全选',
        back: '上一级',
        leftEmptyTips: '似乎没有数据',
        next: '下级',
        clear: '清空',
        rightEmptyTips: '请点击左侧列表选择数据',
        leftTips: '已选{data}项'
    },
    menu: {
        flowable: '工作流',
        flowableDesc: '审批工作流创建 、编辑及其他设置操作',
        userFace: '人脸识别',
        userFaceDesc: '上传人脸识别需要的数据'
    },
    userFace: {
        dialogTitle: '上传',
        submit: '上传',
        reset: '重置',
        search: '搜索',
        dataValue: {existence: '存在', absence: '不存在'},
        table: {
            idLabel: '设备编号',
            usernameLabel: '设备账号',
            nickNameLabel: '设备名称',
            imageLabel: '图片',
            operateLabel: '操作',
            dataLabel: '人脸数据',
            quantityLabel: '录入数量',
            indexLabel: "序号",
            dataUserNameLabel: '人员名称',
        },
        query: {
            usernameLabel: '用户账号',
            nickNameLabel: '用户昵称',
            usernamePlaceholder: '请输入用户账号',
            nickNamePlaceholder: '请输入用户昵称'
        }
    }
}
export default zh
